import ecommerce from '../../../../../ecommerce/ecwid/custom';
import dom from '../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../wrapper/WidgetWrapper';

const updateHeaderStyles = () => {
  const elFixedHeader = dom.getElement('.header_fixed-background');

  if (!elFixedHeader) return;

  dom.updateStyle(elFixedHeader, { opacity: 1 });
};

class CartPage extends WidgetWrapper {
  init = async () => {
    try {
      const elCartBlock = dom.getElement('#mfe-docking-container');
      const section = elCartBlock?.closest('.s-section');
      let emptyCart = false;
      let backCountClick = 0;

      updateHeaderStyles();

      if (section) {
        section.removeAttribute('style');
        dom.removeElement(dom.getElement('.section_bg', section));
      }

      dom.window.history.replaceState(
        '',
        document.title,
        `${window.location.origin}${window.location.pathname}#/cart`
      );

      dom.window.onhashchange = () => {
        if (dom.window.location.hash || !emptyCart) return;

        if (backCountClick === 0) {
          dom.window.history.replaceState(
            '',
            document.title,
            `${window.location.origin}${window.location.pathname}#/cart`
          );
        } else dom.window.history.go(-3);

        backCountClick += 1;
      };

      dom.updateStyle(elCartBlock, { minHeight: `${Math.round(dom.window.innerHeight / 2)}px` });
      dom.addClass(elCartBlock, 'spinner');
      await ecommerce.provider.cart.init();
      await ecommerce.provider.cart.openCart();
      dom.removeClass(elCartBlock, 'spinner');

      const elTitle = dom.getElement('title');

      elTitle.innerHTML = 'Your cart';

      const originRemoveItem = dom.window.localStorage.removeItem.bind(window.localStorage);

      Storage.prototype.removeItem = (name) => {
        originRemoveItem(name);

        if (name !== 'stand-alone-checkout-cart') return;

        emptyCart = true;
      };
    } catch (error) {
      console.error(error);
    }
  };
}

export default CartPage;
